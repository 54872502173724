﻿.vol-map {
    .btn.use-location {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $sae-black;
        z-index: 1000;
    }
}

.leaflet-container, .vol-map {
    min-height: 500px;
}