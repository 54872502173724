﻿.outline {
    $border-width: 2px;
    &.sae-white      { border: $border-width solid $sae-white;     }
    &.sae-light-gray { border: $border-width solid $sae-light-gray;}
    &.sae-gray       { border: $border-width solid $sae-gray;      }
    &.sae-dark-gray  { border: $border-width solid $sae-dark-gray; }
    &.sae-black      { border: $border-width solid $sae-black;   }
    &.sae-blue       { border: $border-width solid $sae-blue;      }
    &.sae-dark-blue  { border: $border-width solid $sae-dark-blue; }
    &.sae-green      { border: $border-width solid $sae-green;     }
    &.sae-dark-green { border: $border-width solid $sae-dark-green;}
    &.sae-yellow     { border: $border-width solid $sae-yellow;    }
    &.sae-orange     { border: $border-width solid $sae-orange;    }
    &.sae-red        { border: $border-width solid $sae-red;       }
    &.sae-xlight-gray{ border: $border-width solid $sae-xlight-gray }
    &.sae-dark-red   { border: $border-width solid $sae-dark-red    }
}

.solid {
    &.sae-white      { background-color: $sae-white;}
    &.sae-light-gray { background-color: $sae-light-gray;}
    &.sae-gray       { background-color: $sae-gray;}
    &.sae-dark-gray  { background-color: $sae-dark-gray;}
    &.sae-black      { background-color: $sae-black;}
    &.sae-blue       { background-color: $sae-blue;}
    &.sae-dark-blue  { background-color: $sae-dark-blue;}
    &.sae-green      { background-color: $sae-green;}
    &.sae-dark-green { background-color: $sae-dark-green;}
    &.sae-yellow     { background-color: $sae-yellow;}
    &.sae-orange     { background-color: $sae-orange;}
    &.sae-red        { background-color: $sae-red;}
    &.sae-white-translucent      { background-color: $sae-white-translucent     ;}
    &.sae-light-gray-translucent { background-color: $sae-light-gray-translucent;}
    &.sae-gray-translucent       { background-color: $sae-gray-translucent      ;}
    &.sae-dark-gray-translucent  { background-color: $sae-dark-gray-translucent ;}
    &.sae-black-translucent      { background-color: $sae-black-translucent     ;}
    &.sae-blue-translucent       { background-color: $sae-blue-translucent      ;}
    &.sae-dark-blue-translucent  { background-color: $sae-dark-blue-translucent ;}
    &.sae-green-translucent      { background-color: $sae-green-translucent     ;}
    &.sae-dark-green-translucent { background-color: $sae-dark-green-translucent;}
    &.sae-yellow-translucent     { background-color: $sae-yellow-translucent    ;}
    &.sae-orange-translucent     { background-color: $sae-orange-translucent    ;}
    &.sae-red-translucent        { background-color: $sae-red-translucent       ;}
    &.sae-xlight-gray-translucent{ background-color: $sae-xlight-gray-translucent; }
    &.sae-dark-red-translucent   { background-color: $sae-dark-red-translucent;    }
}

.color {
    &.sae-white                     { color: $sae-white;}
    &.sae-light-gray                { color: $sae-light-gray;}
    &.sae-gray                      { color: $sae-gray;}
    &.sae-dark-gray                 { color: $sae-dark-gray;}
    &.sae-black                     { color: $sae-black;}
    &.sae-blue                      { color: $sae-blue;}
    &.sae-dark-blue                 { color: $sae-dark-blue;}
    &.sae-green                     { color: $sae-green;}
    &.sae-dark-green                { color: $sae-dark-green;}
    &.sae-yellow                    { color: $sae-yellow;}
    &.sae-orange                    { color: $sae-orange;}
    &.sae-red                       { color: $sae-red;}
    &.sae-white-translucent         { color: $sae-white-translucent     ;}
    &.sae-light-gray-translucent    { color: $sae-light-gray-translucent;}
    &.sae-gray-translucent          { color: $sae-gray-translucent      ;}
    &.sae-dark-gray-translucent     { color: $sae-dark-gray-translucent ;}
    &.sae-black-translucent         { color: $sae-black-translucent     ;}
    &.sae-blue-translucent          { color: $sae-blue-translucent      ;}
    &.sae-dark-blue-translucent     { color: $sae-dark-blue-translucent ;}
    &.sae-green-translucent         { color: $sae-green-translucent     ;}
    &.sae-dark-green-translucent    { color: $sae-dark-green-translucent;}
    &.sae-yellow-translucent        { color: $sae-yellow-translucent    ;}
    &.sae-orange-translucent        { color: $sae-orange-translucent    ;}
    &.sae-red-translucent           { color: $sae-red-translucent       ;}
    &.sae-xlight-gray-translucent   { color: $sae-xlight-gray-translucent; }
    &.sae-dark-red-translucent      { color: $sae-dark-red-translucent;    }
}


.border {
    border: 1px solid #1e1e1e;
    &.sae-white                     { border-color: $sae-white;}
    &.sae-light-gray                { border-color: $sae-light-gray;}
    &.sae-gray                      { border-color: $sae-gray;}
    &.sae-dark-gray                 { border-color: $sae-dark-gray;}
    &.sae-black                     { border-color: $sae-black;}
    &.sae-blue                      { border-color: $sae-blue;}
    &.sae-dark-blue                 { border-color: $sae-dark-blue;}
    &.sae-green                     { border-color: $sae-green;}
    &.sae-dark-green                { border-color: $sae-dark-green;}
    &.sae-yellow                    { border-color: $sae-yellow;}
    &.sae-orange                    { border-color: $sae-orange;}
    &.sae-red                       { border-color: $sae-red;}
    &.sae-white-translucent         { border-color: $sae-white-translucent     ;}
    &.sae-light-gray-translucent    { border-color: $sae-light-gray-translucent;}
    &.sae-gray-translucent          { border-color: $sae-gray-translucent      ;}
    &.sae-dark-gray-translucent     { border-color: $sae-dark-gray-translucent ;}
    &.sae-black-translucent         { border-color: $sae-black-translucent     ;}
    &.sae-blue-translucent          { border-color: $sae-blue-translucent      ;}
    &.sae-dark-blue-translucent     { border-color: $sae-dark-blue-translucent ;}
    &.sae-green-translucent         { border-color: $sae-green-translucent     ;}
    &.sae-dark-green-translucent    { border-color: $sae-dark-green-translucent;}
    &.sae-yellow-translucent        { border-color: $sae-yellow-translucent    ;}
    &.sae-orange-translucent        { border-color: $sae-orange-translucent    ;}
    &.sae-red-translucent           { border-color: $sae-red-translucent       ;}
    &.sae-xlight-gray-translucent   { border-color: $sae-xlight-gray-translucent; }
    &.sae-dark-red-translucent      { border-color: $sae-dark-red-translucent;    }
}