﻿ul {
    &.section {
        li {
            @include screen(sm-and-down) {
                a {
                    display: block;
                    padding: 5px 0;
                }
            }
        }
    }

    &.inline {
        li {
            display: inline-block;
        }
    }

    &.no-bullet {
        list-style-type: none;
        padding-left: 0;
    }
}
