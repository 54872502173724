﻿#header {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: 85px;

    @include screen(sm-and-up) {
        &.tall {
            height: 84px;

            #header-sae {
                top: 12px;
                right: 12px;

                img {
                    width: 90px;
                }
            }
        }
    }
}

#header-sae {
    position: absolute;
    top: 8px;
    right: 10px;

    img {
        width: 66px;
    }
}

#home-banner {
    @include screen(md-and-up) {
        margin-top: 25px;
        margin-bottom: 10px;
    }
}

#navbar {
    min-height: 0;
    margin-bottom: 0 !important;
    border: none;
    border-radius: 0;
}

.navbar {
    border: none;
    margin-bottom: 0;
    min-height: 0;

    .open {
        @include screen(xs-and-down) {
            .dropdown-menu > li > a {
                color: $sae-white;
            }
        }
    }

    .dropdown-menu > li > a :hover {
        color: $sae-black !important;
    }
}

.navbar-toggle {
    background: none;
    position: absolute;
    top: 0px;
    right: 0px;

    .icon-bar {
        background: $sae-white;
    }
}

/*.navbar-toggler {
    background: none;
    position: absolute;
    top: 1px;
    right: -5px;

    .icon-bar {
        background: $sae-white;
    }
}*/

ul.navbar-nav > li > a {
    padding: 8px 10px;
    color: #eee;
    font-size: 14px;
}
 