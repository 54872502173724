﻿table {

    &.borderless > tbody > tr > td, &.borderless > thead > tr > th {
        border: none;
    }

    @include screen(sm-and-down) {
        &.table-responsive {
            border: none;
            width: 100%;

            thead {
                display: none;
            }

            tbody {
                tr {
                    &:nth-of-type(2n) {
                        background-color: inherit;
                    }

                    td {
                        display: block;
                        text-align: left;
                        border-left: 1px solid $sae-light-gray;
                        border-right: 1px solid $sae-light-gray;

                        &:before {
                            content: attr(data-th);
                            display: block;
                            text-align: left;
                            text-transform: uppercase;
                            font-size: 0.7em;
                            font-weight: bold;
                        }

                        &:first-child {
                            background: #f0f0f0;
                            font-weight: bold;
                            font-size: 1.3em;
                        }

                        &:last-child {
                            margin-bottom: 20px;
                            border-bottom: 1px solid $sae-light-gray-translucent;
                        }

                        .ItemEdit {
                            display: inline-block;
                            box-sizing: border-box;
                            text-align: left;
                            padding: 0 15px 0 0;
                            margin: 5px 0 5px 0;

                            a {
                                display: inline-block;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
