﻿body {
    font-family: Arial, sans-serif !important;
    font-size: 14px;
}

#content-wrapper {
    background: #eee;
}

#content {
    background: #fff;
    padding: 15px;

    &.min-height {
        min-height: 600px;
    }
}

.CodeMirror, .CodeMirror-scroll {
    min-height: 200px;
    width: 100%;
}

.label-space {
    margin-top: 20px;
}

.padd-xs {
    padding: 5px;
}

.padd-sm {
    padding: 10px;
}

.padd-md {
    padding: 15px;
}

.padd-lg {
    padding: 20px;
}

.no-padd {
    padding: 0 !important;
}

.no-padd-bottom {
    padding-bottom: 0 !important;
}

.no-padd-top {
    padding-top: 0 !important;
}

.no-padd-left {
    padding-left: 0 !important;
}

.no-padd-right {
    padding-right: 0 !important;
}

.margin-xs {
    margin: 5px;
}

.margin-sm {
    margin: 10px;
}

.margin-md {
    margin: 15px;
}

.margin-lg {
    margin: 20px;
}

.no-margin {
    margin: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-left {
    margin-left: 0 !important;
}

.no-margin-right {
    margin-right: 0 !important;
}
