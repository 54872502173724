﻿table {
    max-width: 100%;
    background-color: transparent;
    border-collapse: separate;
    border-color: gray;
}

input, button, select, textarea {
    font-size: 14px;
}

.inline {
    display: inline-block;
}

.form-inline {
    @include screen(sm-and-down) {
        .form-control {
            display: inline-block;
        }

        .btn.btn-small {
            margin-top: 5px;
        }
    }
}

.form-control {
    &.form-control-very-small {
        max-width: 100px;

        @include screen(xs-only) {
            max-width: 75%;
        }
    }
}

.dropdown-submenu {
    position: relative;

    & > .dropdown-menu {
        top: 0;
        left: 100%;
        margin-top: -6px;
        margin-left: -1px;
        -webkit-border-radius: 0 6px 6px 6px;
        -moz-border-radius: 0 6px 6px 6px;
        border-radius: 0 6px 6px 6px;
    }

    & > a:after {
        display: block;
        content: " ";
        float: right;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px 0 5px 5px;
        border-left-color: #cccccc;
        margin-top: 5px;
        margin-right: -10px;
    }

    &:hover {
        & > .dropdown-menu {
            display: block;
        }

        & > a:after {
            border-left-color: #ffffff;
        }
    }

    &.pull-left {
        float: none;

        .dropdown-menu {
            left: -100%;
            margin-left: 10px;
            -webkit-border-radius: 6px 0 6px 6px;
            -moz-border-radius: 6px 0 6px 6px;
            border-radius: 6px 0 6px 6px;
        }
    }
}

.panel {
    position: relative;
    margin: 15px 0 50px 0;
    border: 1px solid $sae-light-gray-translucent !important;

    &.panel-default > .panel-heading,
    &.panel-info > .panel-heading,
    &.panel-success > .panel-heading,
    &.panel-warning > .panel-heading,
    &.panel-danger > .panel-heading {
        background-image: none;

        span {
            font-weight: bold;
        }
    }

    .panel-top {
        position: relative;
        top: -25px;
    }
}

.btn {
    &.no-style {
        border: none;
        padding: 0;
        background: transparent;
    }

    &.link-style {
        @extend .no-style;
        color: #428bca;

        &:hover {
            text-decoration: underline;
        }
    }

    &.btn-default,
    &.btn-primary,
    &.btn-success,
    &.btn-error,
    &.btn-danger,
    &.btn-warning,
    &.btn-info {
        background-image: none;
    }

    &.btn-grey {
        @extend .btn-default;
        background-color: lighten($sae-light-gray-translucent, 10%);
        border-color: darken($sae-light-gray, 15%);
        color: #1e1e1e;

        &:hover {
            background-color: $sae-light-gray-translucent;
        }
    }

    &.btn-block-xs-only {
        @include screen(xs-only) {
            display: block;
            width: 100%;
        }
    }
}

.fileUpload {
    position: relative;
    overflow: hidden;
    margin: 10px;

    input.upload {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        padding: 0;
        font-size: 20px;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
    }
}
