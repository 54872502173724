﻿.notch-box {
    margin-top: 20px;
    margin-bottom: 20px;

    .inner {
        padding: 18px 16px 0px 16px;

        h1, h2, h3, h4, h5 {
            text-transform: uppercase;
            font-weight: bold;
            margin: 0;
            padding: 0;
            padding-bottom: 15px;
        }
    }

    &.solid {
        .notch {
            border-top: 30px solid;
            border-right: 18px solid transparent;
            border-left: none;
            border-bottom: none;
        }
    }

    &.outline {
        .notch {
            border-right: none;
            border-top: none;
            border-bottom: none;
            height: 35px;
            transform: rotate(-149deg);
            -webkit-transform: rotate(-149deg);
            -moz-transform: rotate(-149deg);
            -o-transform: rotate(-149deg);
            -ms-transform: rotate(-149deg);
            position: absolute;
            margin-top: -17.5px;
            margin-left: 7.2px;
        }

        table {
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;
        }

        td {
            &.top {
                border-bottom: none;
            }

            &.bottom-left {
                height: 29px;
                border-top: none;
                border-right: none;
            }

            &.bottom-right {
                border: none;
                width: 17px;
            }
        }
    }
}
