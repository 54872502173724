﻿#footer {
    font-size: 11px;
    color: #fff;
    width: 100%;
    margin-bottom: 20px;
    padding-top: 30px;
}

#des-logo {
    float: right;
    margin: 15px 15px 8px 15px;

    img {
        width: 150px;
    }
}

.home-footer-box a {
    color: #fff;
    display: block;
    font-size: 16px;
    margin-bottom: 2px;
}

.social-icons img {
    width: 50px;
    margin: 3px;
}
