﻿/**
* UpdateInquiry Styles
*/

.ItemEdit.icon {
    input[type=image] {
        @include screen(sm-and-down) {
            width:32px;
        }
    }
}

/**
* ViewInquiry Styles
*/

div.rule-management-action-box {
    background: #eaf5fb;
}

.view-inquiry-header {
    margin-top: 48px;

    & > div:first-child > .row {
        margin-bottom: 48px;
    }

    &.no-header {
        margin-top: 0;
    }
}

div.no-buttons {
    .inquiry-worked-by {
        height: 42px;
    }
}

.inquiry-worked-by {
    position: absolute;
    top: -35px;
    left: 0;
    width: 100%;
    height: 84px;
    line-height: 32px;
    text-align: center;
    padding: 5px 0;
    background: $sae-dark-blue-translucent;
    color: $sae-white;

    a {
        color: $sae-black-translucent;
    }
}
