﻿.text-white {
    color: $sae-white;

    a {
        color: $sae-white;
    }
}

.text-left-on-sm {
    @include screen(sm-and-down) {
        text-align: left;
    }
}

.text-right-on-sm {
    @include screen(sm-and-down) {
        text-align: right;
    }
}
