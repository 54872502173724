﻿//Accepts sizes: xs, sm, md, lg
//Params: {size}-only, {size}-and-up, {size}-and-down
@mixin screen($size) {
    @if $size == "lg-only" {
        @media screen and (min-width: $responsive-break-lg) {
            @content;
        }
    }
    @else if $size == "md-only" {
        @media screen and (min-width: $responsive-break-md) and (max-width: $responsive-break-lg) {
            @content;
        }
    }
    @else if $size == "sm-only" {
        @media screen and (min-width: $responsive-break-sm) and (max-width: $responsive-break-md) {
            @content;
        }
    }
    @else if $size == "xs-only" {
        @media screen and (max-width: $responsive-break-xs) {
            @content;
        }
    }
    @else if $size == "lg-and-down" {
        @media screen and (max-width: $responsive-break-lg) {
            @content;
        }
    }
    @else if $size == "md-and-down" {
        @media screen and (max-width: $responsive-break-md) {
            @content;
        }
    }
    @else if $size == "sm-and-down" {
        @media screen and (max-width: $responsive-break-sm) {
            @content;
        }
    }
    @else if $size == "xs-and-down" {
        @media screen and (max-width: $responsive-break-xs) {
            @content;
        }
    }
    @else if $size == "lg-and-up" {
        @media screen and (min-width: $responsive-break-md) {
            @content;
        }
    }
    @else if $size == "md-and-up" {
        @media screen and (min-width: $responsive-break-md) {
            @content;
        }
    }
    @else if $size == "sm-and-up" {
        @media screen and (min-width: $responsive-break-sm) {
            @content;
        }
    }
    @else if $size == "xs-and-up" {
        @media screen and (min-width: $responsive-break-xs) {
            @content;
        }
    }
}

.sm-and-down {
    @include screen(md-and-up) {
        display: none;
    }

    @include screen(sm-and-down) {
        display: block;
    }
}
